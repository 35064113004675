import { Typography } from "@mui/material";

const CustomCopyrightSection = () => {
  return (
    <Typography sx={{ color: "white", marginBottom: "20px" }}>
      Copyright by GreenHoop 2024 <br /> v1-0-0beta-388
    </Typography>
  )
}

export default CustomCopyrightSection;
